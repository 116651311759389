<!--
Name: Raymond Chen
Date: 09/30/2023
Email: haojunchen51@gmail.com

Profile page
-->
<template>
  <div class="container">
    <div class="topSection">
      <div class="infoSection">
        <div class="icon"><img :src="icon" /></div>
        <div class="name">
          {{ inforData.FirstName }} {{ inforData.LastName }}
        </div>
        <div class="school">
          {{ inforData.School }} <br />
          <div class="location">
            {{ inforData.Location }} &nbsp; &#x2022;&nbsp;
            {{ inforData.Timezone }}
          </div>
        </div>
      </div>
    </div>
    <div class="midSection">
      <div class="experience">
        <div class="experienceTtile">Experience</div>
        <div class="experienceContent">
          <Experience :userData="experienceData" />
          <Experience :userData="experienceData" />
          <Experience :userData="experienceData" />
        </div>
        <div class="experienceLink">
          <div class="experienceLinkCotnent">
            Show all 10 experiences &nbsp;
            <a href="url"><img :src="arrow" /></a>
          </div>
        </div>
      </div>
      <div class="skillAndInterests">
        <div class="skills"><Skill :skillData="skillData" /></div>
        <div class="interest"><Interest :interestData="interestData" /></div>
      </div>
    </div>
    <div class="bottomSection">
      <div class="projects">
        <div class="projectTitle">Project</div>
        <div class="projectContent"><Project :projectData="projectData" /></div>
        <div class="projectLink">
          <div class="projectLinkContent">
            Show all 5 project &nbsp; <a href="url"><img :src="arrow" /></a>
          </div>
        </div>
      </div>
      <div class="awards"><Award :awardData="awardData" /></div>
    </div>
  </div>
</template>

<script>
import Experience from "./profileCompoents/ProfileExperience.vue";
import experienceData from "@/assets/profilePageData/experienceData.json";
import icon from "@/assets/maleUser.png";
import arrow from "@/assets/Line 12.png";

import linkImg from "@/assets/Link.png";
// import Info from "./profileCompoents/ProfileInfo.vue";
import inforData from "../assets/profilePageData/genralData.json";
import Skill from "./profileCompoents/ProfileSkill.vue";
import skillData from "../assets/profilePageData/skillData.json";
import Interest from "./profileCompoents/ProfileInterest.vue";
import interestData from "../assets/profilePageData/interestData.json";
import Award from "./profileCompoents/ProfileAward.vue";
import awardData from "../assets/profilePageData/awardData.json";
import Project from "./profileCompoents/ProfileProject.vue";
import projectData from "../assets/profilePageData/projectData.json";
export default {
  components: { Experience, Skill, Interest, Project, Award },
  data() {
    return {
      arrow: arrow,
      linkImg: linkImg,
      icon: icon,
      experienceData: experienceData,
      inforData: inforData,
      skillData: skillData,
      interestData: interestData,
      awardData: awardData,
      projectData: projectData,
    };
  },
};
</script>

<style scoped>
* {
  color: white;
  font-family: "Inter", sans-serif;
  min-width: 0;
  min-height: 0;
  align-items: center;
  justify-items: center;
}

.container {
  background-image: url("../../public/KINECT.png");
  display: grid;
  grid-template-columns: 0.4fr 2fr 0.4fr;
  grid-template-rows: 600px 1400px 800px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". topSection ."
    ". midSection ."
    ". bottomSection .";
  align-items: stretch;
  margin-bottom: 5%;
}
/* -------------------------------------------------TOP SECTION-------------------------------------------------- */

.topSection {
  grid-area: topSection;
}
.infoSection {
  margin-top: 10%;
  display: grid;
  grid-template-columns: 1fr 1.6fr;
  grid-template-rows: 1.6fr 0.7fr 0.7fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  height: 60%;
  width: 1200px;
  justify-self: center;
  align-items: center;
  align-self: flex-start;
}
.icon {
  grid-area: 1 / 1 / 4 / 2;
}

.name {
  grid-area: 1 / 2 / 2 / 3;
  text-align: left;
  font-size: 100px;
  justify-self: start;
  align-self: center;
}

.school {
  grid-area: 2 / 2 / 4 / 3;
  text-align: left;
  font-size: 35px;
  justify-self: start;
  align-self: start;
}

.location {
  text-align: left;
  color: grey;
}
/* -------------------------------------------------MID SECTION-------------------------------------------------- */
.midSection {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "experience skillAndInterests";
  grid-area: midSection;
  height: auto;
  width: 1200px;
  align-items: flex-start;
}

.experience {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 2.6fr 0.2fr;
  gap: 0px 0px;
  grid-template-areas:
    "experienceTtile"
    "experienceContent"
    "experienceLink";
  justify-self: stretch;
  align-self: stretch;
  grid-area: experience;
  border-radius: 25px;
  grid-area: 1/1/1/2;
  justify-self: start;
  align-self: stretch;
  /* padding: 3%; */
  width: 600px;
  height: 1285.27px;
  background: rgba(255, 255, 255, 0.1);
}
.experienceTtile {
  grid-area: 1 / 1 / 2 / 2;
  font-weight: 800;
  font-size: 50px;
  line-height: 62px;
  color: #ffffff;
  justify-self: left;
  padding-left: 5%;
}
.experienceContent {
  grid-area: 2 / 1 / 3 / 2;
  /* padding-left: 50%; */
  align-self: start;
}
.experienceLink {
  grid-area: 3 / 1 / 3 / 2;
  grid-area: experienceLink;
  /* justify-self: start; */
  align-self: end;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 0px 0px 30px 30px;
  width: 600px;
  height: 50px;
}
.experienceLinkCotnent {
  text-align: left;
  padding: 3%;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;

  color: #ffffff;
}
.skillAndInterests {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "skills"
    "interest";
  grid-area: skillAndInterests;
  row-gap: 85px;
  align-self: start;
}

.skills {
  /* background-image: url("../../public/KINECT.png"); */
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  grid-area: skills;
  width: 370px;
  height: 750px;
  justify-items: center;
  align-self: start;
}

.interest {
  /* background-image: url("../../public/KINECT.png"); */
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  grid-area: interest;
  width: 370px;
  align-self: start;
}
/* -------------------------------------------------Bottom SECTION-------------------------------------------------- */

.bottomSection {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "projects awards";
  grid-area: bottomSection;
  height: auto;
  width: 1200px;
  align-self: center;
}

.projects {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 2.2fr 0.4fr;
  gap: 0px 0px;
  grid-template-areas:
    "projectTitle"
    "projectContent"
    "projectLink";
  grid-area: projects;

  border-radius: 25px;
  grid-area: 1/1/1/2;
  justify-self: start;
  align-self: stretch;
  /* padding: 3%; */
  width: 600px;
  height: 800px;
  background: rgba(255, 255, 255, 0.1);
}
.projectTitle {
  grid-area: projectTitle;
  font-weight: 800;
  font-size: 50px;
  line-height: 62px;
  color: #ffffff;
  justify-self: left;
  padding-top: 5%;
  padding-left: 5%;
}

.projectContent {
  grid-area: projectContent;
  justify-items: center;
}
.projectLink {
  grid-area: projectLink;
  align-self: end;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 0px 0px 30px 30px;
  width: 600px;
  height: 50px;
}
.projectLinkContent {
  text-align: left;
  padding: 3%;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff;
}
.awards {
  grid-area: awards;
  width: 370px;
  border-radius: 25px;
  justify-self: start;
  align-self: stretch;
  /* padding: 3%; */
  width: 370px;
  height: 800px;
  background: rgba(255, 255, 255, 0.1);
}
</style>





