<template>
<AppNavbar />
  <div class="main">
    <div class="center-container">
      <form>
        <h1 class="Login">Login</h1>
        <div>
          <input v-model="username" placeholder="username" required />
        </div>
        <div>
          <input
            v-model="password"
            type="password"
            placeholder="password"
            required
          />
        </div>
        <div>
          <RouterLink to="/profile"><button class="button"><span> Login</span></button></RouterLink>
        </div>
        <div>
          <RouterLink to="/profile"><button class="button"><span> Login with Google</span></button></RouterLink>
        </div>
      </form>
    </div>
  </div>
  <AppFooter />
</template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "LoginPage",
  components: { AppNavbar, AppFooter },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    Login() {
      console.log(this.username);
      console.log(this.password);
      // Send the data through api to login
      this.username = "";
      this.password = "";
    },
    GoogleLogin() {},
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-container {
  background-color: #141313;
  text-align: center;
  padding: 15%;
  width: 100vw;
}

body {
  background-color: black;
  color: white;
}

.Login {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  margin: 50px;
  color: #fff7f7;
  position: relative;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid white;
  padding: 10px;
  margin: 5px;
  margin-bottom: 40px;
  width: 600px;
  color: white;
}

input:hover,
input:focus {
  border-bottom: 1px solid #fff7f7;
}

button {
  white-space: nowrap;
  position: relative;
  display: inline-block;
  margin: 20px 10px 0 0;
  padding: 24px 100px;
  background: black;
  border: 2px black;
  border-radius: 30px;
  box-shadow: 0 0 10px #847a57;
  /* Font style */
  font-size: 12px;
  letter-spacing: 2px;
  color: #fff7f7;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 700;
  /* Position */
  transition: all 0.3s ease; /* Add a smooth transition */
}

button:hover {
  background: white; /* Change background color on hover */
  color: black; /* Change text color on hover */
  border: 2px white; /* Change border color on hover */
  box-shadow: 0 0 10px #fff7f7; /* Change box shadow on hover */
  transform: scale(1.05); /* Add a hover effect */
}

button:active {
  transform: scale(0.95);
}

button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  cursor: pointer;
}
</style>
