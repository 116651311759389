<template>
  <div class="projectContainer">
    <div
      class="projectCard"
      v-for="(item, index) in projectData.Project"
      :key="index"
    >
      <div class="header">
        <div class="projectName">
          <h1>{{ item.Name }}</h1>
        </div>
        <div class="projectDate">
          <p>{{ item.StartDate }} -- {{ item.EndDate }}</p>
        </div>
      </div>
      <div class="projectBody">
        <p>{{ item.Description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { projectData: Object },
};
</script>

<style scoped>
.projectContainer {
  display: grid;
}
.projectCard {
  padding: 1%;
  align-self: center;
  grid-row: auto;
  width: 550px;
  height: 200px;
  background: #2c2c2c;
  border-radius: 20px;
  margin-top: 5%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.projectName {
  font-family: "Lexend";
  font-style: normal;
  /* font-weight: 400;
  font-size: 20px; */
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 3%;
}
.projectDate {
  font-family: "Lexend";
  font-style: normal;
  /* font-weight: 300; */
  /* font-size: 18px; */
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #797979;
}
.projectBody {
  padding-left: 3%;
  font-family: "Lexend";
  font-style: normal;
  /* font-weight: 300; */
  /* font-size: 15px; */
  line-height: 25px;
  display: flex;
  align-items: center;

  color: #ffffff;
}
</style>
