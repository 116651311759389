<template>
  <div class="InterestContainer">
    <div class="interestTitle">
      <h1>Interest</h1>
    </div>
    <div class="list">
      <ul>
        <li
          class="listItem"
          v-for="(item, index) in interestData.interest"
          :key="index"
        >
          {{ item }}
          <div class="InterestLink" v-if="index === interestData.interest.length - 1">
            <a href="url"><img :src="arrow" /></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import arrow from "@/assets/Line 12.png";

export default {
  props: { interestData: Object },
  data() {
    return {
      arrow: arrow,
    };
  },
};
</script>

<style scoped>
.InterestContainer {
  padding-left: 5%;
}
.interestTitle {
  padding-left: 5%;
  padding-top: 5%;
}
.list {
  font-size: 30px;
}
.listItem {
  padding: 3%;
}
.InterestLink{
  padding-left: 90%;
}
</style>
