<template>
    <div class="main-container">
        <div class="image">
            <img src="../../assets/image12.png">
        </div>
        <div class="text-container">
            <p class="paragraph">STUDENT TESTIMONIES</p>
            <h1 class="heading">“Most valuable time <br/>I’ve spent”</h1>
            <p class="paragraph1">
            "I recently completed an internship at Resilience Inc and it <br />
            was an incredible experience. From day one, I was <br />
            welcomed into the team and given meaningful projects to <br />
            work on. The company culture is fantastic and everyone <br />
            is supportive and collaborative. I learned so much during <br />
            my time there and felt like I was making a real <br />
            contribution to the company."<br /><br />
            
            <span class="max">
            -Max Xiong <br />
            Rutgers Preparatory School
            </span>
            </p>
        </div>
    </div>
</template>


<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

    .main-container {
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        align-items: center;
        min-width: 100vw;
        max-width: 100vw;
        background-color: #090909;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .image {
        width: 32vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 13vh;
        margin-left: 15vw;
        pointer-events: none;
        user-select: none;
    }

    .paragraph {
        font-family: 'Lexend', sans-serif;
        color: #FFF;
        font-size: 2vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 5.4px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10vw;
        margin-top: 10vh;
    }

    .heading {
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 6vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .paragraph1 {
        color: #C1C1C1;
        text-align: justify;
        font-family: 'Inter', sans-serif;
        font-size: 2.5vh;
        font-style: normal;
        font-weight: 500;
        line-height: 157.023%; /* 37.685px */
    }
    
    .max {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 500;
    line-height: 157.023%;
    }
</style>
