<template>
  <AppNavbar />
  <div class="main">
    <div class="headingContainer">
      <h1 class="heading">Bounties customized for you.</h1>
      <div class="search-container">
        <input type="text" class="search-input" placeholder="Search" />
        <i class="fas fa-search"></i>
      </div>
    </div>
    <div class="headerLineContainer">
      <div class="lineLeft"><img class="line" :src="line" /></div>
      <h1 class="searchResultHeader">Search Results</h1>
      <div class="lineRight"><img class="line" :src="line" /></div>
    </div>
    <div class="google-images">
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
    </div>
    <div class="google-images">
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
    </div>
    <div class="google-images">
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
      <RouterLink to="/bounties/detail"><a class="google-link"><img src="../assets/google.png" class="google-image" /></a></RouterLink>
    </div>
  </div>
  <AppFooter />
</template>

<script>
import line from "../assets/Line 17.png";
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  components: { AppFooter, AppNavbar },
  data() {
    return {
      line: line,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

.main {
  min-height: 100vh;
  min-width: 100vw;
  background-image: url("../../public/KINECT.png");
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.headingContainer {
  align-items: center;
  text-align: center;
}

.heading {
  font-family: "Lexend", sans-serif;
  color: white;
  font-size: 3.3333333333333335vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15vh;
}

.search-container {
  display: flex;
  justify-content: center;
  background: #000;
  width: 80%;
  border-radius: 50px;
  padding: 3.141361256544503vh;
  box-shadow: 0 0 10px #847a57;
  margin-top: 10vh;
  margin: 50px 0 0 2rem;
}

.search-input {
  border: none;
  outline: none;
  background: transparent;
  color: white;
  font-family: "Lexend", sans-serif;
  font-size: 2vh;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  flex: 1;
}

.search-container .fas {
  color: white;
  margin-right: 1vh;
}

.headerLineContainer {
  display: flex;
  align-items: center;
  text-align: center;
}

.searchResultHeader {
  font-family: "Lexend", sans-serif;
  font-size: 5vh;
  align-items: center;
  text-align: center;
  color: #979797;
  white-space: nowrap;
}

.lineLeft {
  margin-right: 5vw;
}

.lineRight {
  margin-left: 5vw;
}

.google-images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.google-image {
  width: 100%;
  height: auto;
  transition: transform 0.2s;
  cursor: pointer;
}

.google-link {
  text-decoration: none;
  user-select: none;
}

.google-image:hover {
  transform: scale(1.1);
}
</style>
