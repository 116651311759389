<template>
    <nav :style ="{width: navbarWidth + 'px'}" class="navbar">
      <div class="navbar-left">
        <router-link to="/" class="logo-link"><h3 class="logo">Kinect</h3></router-link>
      </div>
      <div class="navbar-middle">
        <router-link to="/" class="navbar-middle-text">Home</router-link>
        <router-link to="/bounties" class="navbar-middle-text">Search</router-link>
      </div>
      <div class="navbar-right">
        <router-link to="/login"><button>Login</button></router-link>
        <button class="signup" @click="signup">Signup</button>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name : "AppNavbar",

    data () {
      return {
        navbarWidth: window.innerWidth,
      };
    },
    mounted() {
      window.addEventListener('resize', this.updateWidth);
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.updateWidth);
    },
    methods: {
      updateWidth() {
        this.navbarWidth = window.innerWidth
      }
    },
  };
  </script>

  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem;
    background-color: #000000;
    color: white;
    height: 1.6rem;
    font-family: "Lexend", sans-serif;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .navbar-left {
    flex: 1;
    margin-top: -0.2rem;
  }

  .logo {
    font-size: 1.4rem;
    margin-left: 3.2rem;
    font-weight: bolder;
    color: white;
  }

  .logo-link {
    text-decoration: none;
  }
  
  .navbar-middle {
    flex: 2;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .navbar-middle-text {
    color: white;
    text-decoration: none;
  }

  .navbar-middle router-link {
    padding: 0 0.8rem;
    color: #9A9A9A;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .navbar-middle router-link:hover {
    color: #fff;
  }
  
  .navbar-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;  
  }
  .navbar-right button {
    background-color: #000000;
  }

  .signup {
    background-color: #000000;
    border: 1px solid #908D8D;
    border-radius: 20px;
  }
  
  button {
    margin-left: 10px;
    padding: 8px 16px;
    color: rgb(202, 199, 199);
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
  }
  
  button:hover {
    color: #fff;
  }
  </style>
  