<template>
  <div v-on:click="writeText">
    <svg
      width="106"
      height="104"
      viewBox="0 0 106 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.505 51.8182C105.505 80.1849 82.1141 103.193 53.244 103.193C24.3739 103.193 0.982929 80.1849 0.982929 51.8182C0.982929 23.4514 24.3739 0.44289 53.244 0.44289C82.1141 0.44289 105.505 23.4514 105.505 51.8182Z"
        stroke="url(#paint0_linear_373_323)"
        stroke-width="0.885781"
      />
      <path
        d="M105.505 51.8182C105.505 80.1849 82.1141 103.193 53.244 103.193C24.3739 103.193 0.982929 80.1849 0.982929 51.8182C0.982929 23.4514 24.3739 0.44289 53.244 0.44289C82.1141 0.44289 105.505 23.4514 105.505 51.8182Z"
        stroke="url(#paint1_linear_373_323)"
        stroke-width="0.885781"
      />
      <path
        d="M105.505 51.8182C105.505 80.1849 82.1141 103.193 53.244 103.193C24.3739 103.193 0.982929 80.1849 0.982929 51.8182C0.982929 23.4514 24.3739 0.44289 53.244 0.44289C82.1141 0.44289 105.505 23.4514 105.505 51.8182Z"
        stroke="url(#paint2_linear_373_323)"
        stroke-width="0.885781"
      />
      <!-- Text Element -->
      <text
        x="50%"
        y="50%"
        text-anchor="middle"
        alignment-baseline="middle"
        font-size="14"
        fill="grey"
      >
        {{ Level }}
      </text>
      <text
        x="50%"
        y="60%"
        text-anchor="middle"
        alignment-baseline="middle"
        font-size="10"
        fill="grey"
      >
        {{ Proficiency }}
      </text>
      <defs>
        <linearGradient
          id="paint0_linear_373_323"
          x1="53.244"
          y1="0"
          x2="53.6869"
          y2="8.39124e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#7C7C7C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_373_323"
          x1="53.244"
          y1="0"
          x2="53.6869"
          y2="8.39124e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#7C7C7C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_373_323"
          x1="53.244"
          y1="0"
          x2="53.6869"
          y2="8.39124e-09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#7C7C7C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  
</template>

<script>
export default {
  props: {
    Level: Number,
    Proficiency: String,
    Name: String,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
