<template>
  <div class="skillContainer">
    <div class="skillTitle">
      <h1>Skills</h1>
    </div>
    <div
      class="percentCircle"
      v-for="(item, index) in skillData.Skill"
      :key="index"
    >
      <div class="circle">
        <OneOutFour
          :Level="item.Level"
          :Proficiency="item.Proficiency"
          :Name="item.Name"
        />
      </div>
      <div class="skillName">{{ item.Name }}</div>
      <div class="skillLink" v-if="index === skillData.Skill.length - 1">
        <a href="url"><img :src="arrow" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import OneOutFour from "./skillcircle/oneOutFour.vue";
import arrow from "@/assets/Line 12.png";
export default {
  props: { skillData: Object },
  components: { OneOutFour },
  data() {
    return {
      arrow: arrow,
    };
  },
};
</script>

<style scoped>
.skillContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.6fr 1.1fr 1.1fr 1.1fr 1.1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "."
    "."
    "."
    ".";
  grid-area: skills;
  justify-content: center;
}
.skillTitle {
  grid-row: 1;
  font-weight: 800;
  font-size: 30px;
  line-height: 62px;
  color: #ffffff;
  justify-self: start;
  padding-left: 5%;
}

.percentCircle {
  grid-row: auto;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: 1fr;
  align-self: start;
  justify-self: start;
  padding-left: 10%;
  column-gap: 30%;
}
.circle {
  grid-area: 1/1/1/1;
}
.skillName {
  font-size: 30px;
  padding-top: 18%;
  width: 50px;
  grid: 1/2/1/2;
  align-self: center;
}

.skillLink{
  padding-left: 290%;
}
</style>






