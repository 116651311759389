import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage.vue'
import LoginPage from './components/LoginPage.vue'
import BountiesPage from './components/BountiesPage.vue'
import BountyDetails from './components/BountyDetails.vue'
import UploadSuccessfulPage from './components/UploadSuccessfulPage.vue'
import ProfilePage from './components/ProfilePage.vue'

const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: LoginPage },
  { path: '/bounties', component: BountiesPage },
  { path: '/bounties/detail', component: BountyDetails },
  { path: '/upload-success', component: UploadSuccessfulPage },
  { path: '/profile', component: ProfilePage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
