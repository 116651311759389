<template>
    <footer :style="{ fontSize: footerFontSize + 'px', padding: footerPadding + 'px' }"  class="footer">
      <div class="footer-content">
        <div class="left">
          <p class="footer-text">Kinect ©2023, all rights reserved.</p>
        </div>
        <div class="footer-icons">
          <img :src="mailIcon" alt="Mail" />
          <img :src="instagramIcon" alt="Instagram" />
          <img :src="youtubeIcon" alt="Youtube" />
          <img :src="contactIcon" alt="Contact" />
        </div>
      </div>
    </footer>
  </template>
  

  <script>
    import mailIcon from "@/assets/mailIcon.png";
    import instagramIcon from "@/assets/instagramIcon.png";
    import youtubeIcon from "@/assets/youtubeIcon.png";
    import contactIcon from "@/assets/contactIcon.png";

    export default {
    name : "AppFooter",
        data () {
           return {
            mailIcon: mailIcon,
            instagramIcon: instagramIcon,
            youtubeIcon: youtubeIcon,
            contactIcon: contactIcon,
            footerFontSize: 16,
            footerPadding: 8,
           };
          },
    mounted() {
    window.addEventListener('resize', this.updateStyles);
    },
    beforeUnmount() {
    window.removeEventListener('resize', this.updateStyles);
    },
    methods: {
    updateStyles() {
      // Adjust font size and padding based on the container's width
      const containerWidth = this.$el.clientWidth; // Get the width of the footer container
      this.footerFontSize = containerWidth / 30;
      this.footerPadding = containerWidth / 8;
    },
  },
};

</script>

  <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
  .footer {
    background-color: #0000;
    color: white;
    border-top: 0.2px solid #5f5c5c;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-text {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #EDE4E4;
    margin-left: 2%;
  }
  
  .left {
    flex: 1;
  }
  
  .footer-icons {
    display: flex;
    margin-right: 3rem;
    gap: 1rem;
    color: #F2E7E7;
  }

  .footer-icons img {
    padding: 0.2rem;
    border: 0.1rem solid #908D8D;
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
  }
  .footer-icons:hover {
   cursor: pointer;
  }

  </style>
  