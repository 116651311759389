<!--
Name: Raymond Chen
Date: 10/3/2023
Email: haojunchen51@gmail.com

Experience
-->


<template>
  <div class="experienceCard">
    <div class="header">
      <div class="title">
        <h1>{{ userData.title }}</h1>
      </div>
      <div class="date">
        <p>{{ userData.startDate }} - {{ userData.endDate }}</p>
      </div>
    </div>
    <div class="body">
      <div
        class="experienceDetail"
        v-for="(detail, index) in userData.detail"
        :key="index"
      >
        <p>&#8226;&nbsp;{{ detail }}</p>
      </div>
      <div class="projectLink">
        <a href="{{ userData.projectLink }}"> Project Link Here</a>
        <img :src="linkImg" alt="Link Icon" />
      </div>
      <div class="skills">
        <p>Skills:&nbsp;</p>
        <div
          class="skills"
          v-for="(skill, index) in userData.skills"
          :key="index"
        >
          <p>
            {{ skill
            }}<span v-if="index < userData.skills.length - 1"
              >&#8226;&nbsp;</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="linebreak">
      <hr />
    </div>
  </div>
</template>

  
<script>
import linkImg from "@/assets/Link.png";
export default {
  props: {
    userData: Object,
  },
  data() {
    return {
      linkImg: linkImg,
    };
  },
};
</script>
  
<style scoped>
.experienceCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 350.5px;
  justify-self: center; 
  justify-items: center;
  width: 500px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.date {
  color: grey;
  align-items: center;
}

.projectLink {
  display: flex;
}
.projectLink a {
  color: white;
}
.projectLink img {
  width: 30px;
  height: auto;
}
.skills {
  display: flex;
  align-items: left;
  color: grey;
}
.linebreak {
  width: 100%;
}
</style>
