<template>
    <AppNavbar />
    <div class="main">
      <img src="../assets/GoogleLogo.png" class="logo" />
      <h1 class="heading">Bounty Name</h1>
      <div class="center-container">
        <div class="svg-container">
          <svg
            width="744"
            height="106"
            viewBox="0 0 744 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 14.2752C0 6.39123 6.39122 0 14.2752 0H729.725C737.609 0 744 6.39122 744 14.2752V90.9556C744 98.8395 737.609 105.231 729.725 105.231H14.2752C6.39122 105.231 0 98.8395 0 90.9556V14.2752Z"
              fill="white"
              fill-opacity="0.1"
            />
          </svg>
          <div class="text-container">
            <p class="detail">54 Submissions · $ For Submission</p>
          </div>
        </div>
      </div>
      <div class="description-deadline">
        <p class="description">Description</p>
        <p class="deadline">DEADLINE: 12/1/2023</p>
      </div>
      <p class="paragraph">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus mi massa, a cursus risus <br />
        consectetur non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus mi <br />
        massa, a cursus risus consectetur non. Lorem ipsum dolor sit amet, consectetur <br/>
        Pellentesque finibus mi massa, a cursus risus consectetur non. Lorem ipsum dolor sit amet, consectetur <br/>
        adipiscing elit. Pellentesque finibus mi massa, a cursus risus consectetur non. Lorem ipsum dolor sit <br />
        amet, consectetur adipiscing elit. Pellentesque finibus mi massa, a cursus risus consectetur non. Lorem <br />
        ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus mi massa, a cursus risus <br />
        consectetur non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque finibus mi <br />
        massa, a cursus risus consectetur non. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
        Pellentesque finibus mi massa, a cursus risus consectetur non. Lorem ipsum dolor sit amet, consectetur <br />
        adipiscing elit. Pellentesque finibus mi massa, a cursus risus consectetur non. Lorem ipsum dolor sit <br />
        amet, consectetur adipiscing elit. Pellentesque finibus mi massa, a cursus risus consectetur non. <br />
      </p>

      <h1 class="submission">Submission</h1>
      <div class="input-container">
        <input type="text" placeholder="Web Link" class="text-input" v-model="userText" />
        <RouterLink to="/upload-success"><button class="submit-button">Submit</button></RouterLink>
      </div>
    </div>
    <AppFooter />
  </template>

<script>
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  components: { AppFooter, AppNavbar },
  data() {
    return {
      userText: "",
    };
  },
};
</script>

<style scoped>
  .main {
    min-height: 100vh;
    max-width: 100vw;
    background-image: url("../../public/KINECT.png");
    background-size: contain;
    background-repeat: repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    user-select: none;
    pointer-events: none;
    margin-top: 10vh;
  }

  .heading {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Lexend", sans-serif;
    font-size: 8.5vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .center-container {
    text-align: center;
  }

  .svg-container {
    display: inline-block;
    position: relative;
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  svg {
    width: 100%;
    z-index: 0;
  }

  .detail {
    color: #FFF;
    font-family: "Lexend", sans-serif;
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }

  .description-deadline {
    display: flex;
    justify-content: space-between;
    gap: 38vw;
    margin-top: 10vh;
  }

  .description {
    color: rgba(255, 255, 255, 0.75);
    font-family: "Lexend", sans-serif;
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .deadline {
    color: #FFF;
    font-family: "Lexend", sans-serif;
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .paragraph {
    color: rgba(255, 255, 255, 0.50);
    font-family: "Lexend", sans-serif;
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-input {
    border-radius: 10px;
    background: rgba(101, 101, 101, 0.15);
    width: 40vw;
    height: 4vh;
    text-align: center; 
    font-family: "Lexend", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FFF;
    padding: 10px;
    margin-right: 11vw;
    margin-bottom: 15vh;
  }

  .text-input::placeholder {
    color: rgba(255, 255, 255, 0.50);
  }

  .submit-button {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.20);
    width: 10vw;
    height: 6.5vh;
    color: #FFF;
    text-align: center;
    font-family: "Lexend", sans-serif;
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  .submit-button:hover {
    transform: scale(1.1);
  }

  .submission {
    color: #FFF;
    font-family: "Lexend", sans-serif;
    font-size: 3.4vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10vh;
  }
</style>
