<template>
	<AppNavbar />
	<IntroductionComponent />
	<NetworkComponent />
	<ImpactComponent />
	<TestimoniesComponent />
	<AppFooter />
</template>

<script>
import AppFooter from "./AppFooter.vue";
import AppNavbar from "./AppNavbar.vue";
import IntroductionComponent from "@/components/homeComponents/IntroductionComponent.vue";
import NetworkComponent from "@/components/homeComponents/NetworkComponent.vue";
import ImpactComponent from "@/components/homeComponents/ImpactComponent.vue";
import TestimoniesComponent from "@/components/homeComponents/TestimoniesComponent.vue";

export default {
    name: "App",
    components: {
        AppNavbar,
        IntroductionComponent,
        NetworkComponent,
        ImpactComponent,
        TestimoniesComponent,
        AppFooter,
    },
};
</script>

<style>
#app {
  overflow-x: hidden;
  background-color: black;
}
</style>