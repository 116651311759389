<template>
  <div class="main-container">
    <div class="opportunity-container">
      <p class="opportunity-text">Opportunity Comes Knocking</p>
      <p class="divider-text">|</p>
      <div class="explore-container">
        <router-link to="/bounties" class="router-link"><p class="explore-text">Explore</p></router-link>
        <img src="../../assets/arrow-icon.png" alt="Arrow" />
      </div>
    </div>
    <p class="profits-text">1.4k Nonprofits Need Help</p>
    <p class="number-text">Let’s Make That Number Zero</p>
    <p class="paragraph-text">Kinect creates life-changing experiences for high-school students<br>and organizations worldwide.</p>
    <router-link to="/bounties" class="router-link"><button class="kinect-button">
      <p class="kinect-button-text">Let's Kinect</p>
    </button></router-link>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lexend', sans-serif;
  padding: 5vh;
  gap: 2vh;
  min-height: 76vh;
  max-height: 76vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.opportunity-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3vh;
  border-radius: 9999px;
  border: 1px solid #E3E3E3;
  padding: 0px 30px;
  margin-bottom: 3vh;
  height: 6vh;
}

.opportunity-text, .divider-text {
  color: #C9C9C9;
  font-size: 2vh;
}

.explore-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  cursor: pointer;
}

.explore-text {
  background: linear-gradient(96deg, #8AA4FF 3.09%, #FF6EF9 101.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2vh;
}

.profits-text {
  color: white;
  font-weight: 700;
  font-size: 6.64vh;
  margin: 0;
}

.number-text {
  background: linear-gradient(180deg, rgba(114, 113, 113, 0.80) 0%, rgba(128, 115, 115, 0.40) 57.99%, rgba(144, 144, 144, 0.34) 58%, rgba(141, 118, 118, 0.00) 100%, rgba(219, 218, 218, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6.64vh;
  margin: 0;
}

.kinect-text {
  width: 66%;
  text-align: center;
  font-size: 3.32vh;
  color: #8E8E8E;
  margin: 2vh auto;
}

.kinect-button {
  padding: 2.5vh 5vh;
  border-radius: 9999px;
  box-shadow: 0 0 10px 5px #DBB5B5;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.kinect-button:hover {
  background-color: black;
}

.kinect-button-text {
  font-size: 2vh;
  margin: 0;
  background: linear-gradient(96deg, #505E8E 3.09%, #8B3A87 101.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paragraph-text {
  color: #8E8E8E;
  text-align: center;
  font-family: 'Lexend', sans-serif;
  font-size: 3vh;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.router-link {
  text-decoration: none;
}
</style>