<template>
  <div class="AwardContainer">
    <div class="awardTitle">
      <h1>Awards</h1>
    </div>
    <p class="items" v-for="(item, index) in awardData.awards" :key="index">
      <img :src="trophy" />{{ item }}
    </p>
    <div class="awardLink">
      <a :href="url"><img :src="arrow" /></a>
    </div>
  </div>
</template>


<script>
import trophy from "../../assets/Trophy.png";
import arrow from "@/assets/Line 12.png";
export default {
  props: {
    awardData: Object,
  },
  data() {
    return {
      arrow: arrow,
      trophy: trophy,
    };
  },
};
</script>

<style scoped>
.AwardContainer {
  padding-left: 5%;
}
.awardTitle {
  padding-left: 5%;
  padding-top: 5%;
}
.items {
  padding-left: 5%;
  font-size: 30px;
}

.awardLink {
  padding-left: 90%;
}
</style>
