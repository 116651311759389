<template>
    <div class="main-container">
        <div class="image">
            <img src="../../assets/image9.png">
        </div>
        <div class="text-container">
            <p class="paragraph">IMPACT</p>
            <h1 class="heading">ENGAGE IN <br /><span class="heading-2">MEANINGFUL</span><br/>OPPORTUNITIES</h1>
            <p class="paragraph1">Lorem ipsum dolor sit amet, consectetur adipiscing<br /> elit, sed do eiusmod tempor incididunt ut labore <br /> dolore magna aliqua. Ut enim ad minim veniam, quis<br /> nostrud exercitation ullamco laboris nisi</p>

            <RouterLink to=bounties><button class="getstarted">
                <p class="buttontext">GET STARTED</p>
            </button></RouterLink>
        </div>
    </div>
</template>


<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

    .main-container {
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        align-items: center;
        min-width: 100vw;
        max-width: 100vw;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .image {
        width: 32vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10vh;
        margin-left: 5vw;
        pointer-events: none;
        user-select: none;
    }

    .paragraph {
        font-family: 'Lexend', sans-serif;
        color: #FFF;
        font-size: 2vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 5.4px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20vw;
        margin-bottom: 10vh;
    }

    .heading {
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 6vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .heading-2 {
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 6vh;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .paragraph1 {
        color: #C1C1C1;
        text-align: justify;
        font-family: 'Inter', sans-serif;
        font-size: 2.5vh;
        font-style: normal;
        font-weight: 500;
        line-height: 157.023%; /* 37.685px */
    }

    .getstarted {
        width: 10vw;
        height: 5vh;
        flex-shrink: 0;
        border-radius: 100px;
        border: 1px solid #FFF;
        background-color: #000;
        transition: background-color 0.3s, color 0.3s;
        cursor: pointer;
        margin-top: 5vh;
    }

    .getstarted:hover {
        background-color: #FFF;
    }

    .buttontext {
        color: #FFF;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 1.5vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .getstarted:hover .buttontext {
        color: #000;
    }
</style>
